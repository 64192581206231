.home {
  height: 100vh;

  &__background {
    background-image: linear-gradient(to bottom, #efdfac, #e4d295, #d9c67f, #cdba68, #c1ae51, #bf9541, #bb7d38, #b36433, #9c3f42, #74284a, #421d46, #0b1233);
  }

  &__frame {
    border: 1px solid grey;
    padding: 1%;
    z-index: 100;
  }

  &__frame-box {
    border: 4px solid grey;
    width: 100%;
    height: 100%;
  }

  &__frame-light-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1%;
    margin-left: 1%;

    div {
      font-size: 24px;
    }
  }

  &__frame-light {
    width: 30px;
    height: 30px;
    border: 1px solid green;
    border-radius: 50%;
    background-color: green;
    margin-right: 6px;
  }

  &__greeting-intro {
    display: flex;
    justify-content: center;
    width: 80%;

    @media only screen and (max-width: 768px) {
      // font-size: 40px;
    }
  }

  &__greeting-msg {
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 20%;
    }
  }

  &__greeting-content, &__greeting-content-hi {
    display: flex;
    font-size: 60px;

    .abbr {
      margin-left: 8px;
      margin-right: 8px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__intro-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }


  &__intro-message-container-end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__intro-message {
    display: flex;
    flex-direction: column;
    width: 40%;
  }

  &__sehee-front {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
  }

  &__sehee-back {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5%;
    z-index: 1;
  }

  &__sehee-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @media only screen and (max-width: 768px) {
      align-items: flex-end;
    }
  } 

  &__sehee {
    width: 30%;

    @media only screen and (max-width: 768px) {
      width: 70%;
      margin-left: 6%;
    }
  }

  &__yam-container {
    display: flex;
    align-items: flex-end;
    // z-index: 30;
  }

  &__yam {
    width: 18%;
    margin-left: 20%;
    margin-bottom: 3%;
  }

  &__mango-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 3;
  }

  &__mango {
    width: 30%;
    margin-bottom: 0.5%;
    margin-right: 9%;
  }

  &__desk-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
  }

  &__keyboard-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
  }

  &__keyboard {
    width: 28%;
    margin-bottom: 4%;
  }

  &__coffee-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 3;
  }

  &__intro {
    display: flex;
    justify-content: center;
  }
}

.speech-bubble {
	position: relative;
	background: #efdfac;;
	border-radius: .4em;
  font-size: 36px;
  margin: 5%;
  padding: 3%;
  width: 30%;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    width: 80%;
  }
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: #efdfac;;
	border-bottom: 0;
	border-right: 0;
	margin-left: -10px;
	margin-bottom: -20px;
}


.speech-bubble-right {
	position: relative;
	background: #efdfac;;
	border-radius: .4em;
  font-size: 36px;
  margin: 5%;
  padding: 3%;
  width: 30%;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    width: 80%;
  }
}

.speech-bubble-right:after {
  content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: #efdfac;;
	border-bottom: 0;
	border-left: 0;
	margin-left: -10px;
	margin-bottom: -20px;
}
