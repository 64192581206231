.contacts {
  height: 100vh;
  background-color: #efdfac;

  &__body {
    display: flex;
    justify-content: center;
    background-color: #efdfac;

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
      justify-content: center;
      align-items: center;
    } 
  }

  &__content-subtitle {
    font-size: 35px;
  }

  &__content-links {
    display: flex;
  }

  &__content-link {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
    border: 1px solid black;
  }

  &__content-link:hover {
    cursor: pointer;
  }

  &__content-link-icon {
    height: 30px;
    width: 30px;
  }
}