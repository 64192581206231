.experience {
  background-image: linear-gradient(to bottom, #efdfac, #e4d295, #d9c67f, #cdba68, #c1ae51, #bf9541, #bb7d38, #b36433, #9c3f42, #74284a, #421d46, #0b1233);
  &__company-title {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }

  &__company-title:hover {
    text-decoration: none;
  }

  &__company-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
