.projects {
  height: 100vh;
  background-color: #efdfac;

  &__project-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #efdfac;

    @media only screen and (max-width: 768px) { 
      flex-direction: column;
    }
  }

  &__project-panel {
    width: 40%;

    @media only screen and (max-width: 768px) { 
      width: 100%;
    }
  }

  &__project-img-container {
    display: flex;
    justify-content: center;
    height: 250px;
  }

  &__project-img {
    height: auto;

    @media only screen and (max-width: 768px) { 
      width: 100%;
    }
  }

  &__project-title {
    font-size: 36px;
  }

  &__project-tech {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__project-tech-stack {
    border: 1px solid #dbdbd5;
    background-color: #dbdbd5;
    padding: 2px;
  }

  &__project-description {
    li {
      // list-style: none;
    }
  }
}
