body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #424750; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: 'Montserrat', sans-serif;
}


.h1 {
  font-size: 60px;
  font-weight: 400;

  @media only screen and (max-width: 768px) { 
    font-size: 40px;
    font-weight: 600;
  }
}

.subtitle {
  font-size: 40px;
  font-weight: 700;

  @media only screen and (max-width: 768px) { 
    font-size: 20px;
    font-weight: 800;
  }
}