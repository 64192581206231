.skills {
  height: 100vh;
  background-color: #efdfac;
  
  &__languages {
    display: flex;
    justify-content: center;
  }

  &__languages-sets {
    display: inline-grid;
    grid-template-columns: auto auto auto auto;

    @media only screen and (max-width: 600px) {
      grid-template-columns: auto auto;
    }
  }

  &__languages-sets-item {
    img {
      height: 70px;
      width: 70px;
    }
  }

  &__items {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #efdfac;

    @media only screen and (max-width: 768px) {
      padding-left: 0%;
      padding-right: 0%;
      width: 100%;
      overflow-x: auto; /* Enable horizontal scrolling */
      white-space: nowrap; /* Prevent content from wrapping */
    }

    @media only screen and (max-width: 1024px) {
      padding-left: 3%;
      padding-right: 3%;
      width: 100vw;
      overflow-x: scroll;
    }
  }

  &__items-sets {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;

    @media only screen and (max-width: 768px) {
      flex-shrink: 0; /* Prevents items from shrinking and taking less space */
      width: 100%; /* Make each item take full width */
      margin-right: 10px; /* Add some space between items if desired */
    }
  }

  &__items-sets-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    background:#f7f7f2;
    -webkit-box-shadow:0 10px 6px -6px #777;
    -moz-box-shadow:0 10px 6px -6px #777;
    box-shadow:0 10px 6px -6px #777;

    img {
      width: 60%;
    }
  }

  &__items-sets-content {
    width: 100%;
    list-style-type: none;
    text-align: left;
    font-size: 16px;
  }

  &__items-sets-title {
    width: 100%;
    height: 70px;
    font-size: 22px;
    text-align: center;
  }

  .bullet {
    width: 18px;
  }

  ul {
    padding-inline-start: 0;


    @media only screen and (max-width: 768px) {
      padding-inline-start: 20%;
    }
  }
}
